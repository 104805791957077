import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, AfterViewChecked, ViewChild, OnChanges, Inject, Output, EventEmitter } from '@angular/core';
import { Type } from '@angular/compiler';
import { Options } from 'selenium-webdriver';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIDatasService } from 'src/app/services/apidatas.service';

import { Moment } from 'moment';
import * as moment from 'moment';
import { CustomAction } from 'src/app/models/ui';

@Component({
  selector: 'app-listing-form',
  templateUrl: './listing-form.component.html',
  styleUrls: ['./listing-form.component.css']
})
export class ListingFormComponent<T> implements OnInit, OnChanges {

  @ViewChild("table", { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() noSearch: boolean = false
  @Input() defSortCol: string = null
  @Input() defSortOrder: string = 'desc'

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  private _options: ListingOptions;
  private _datas: any[]
  datas$: BehaviorSubject<any[]>;
  dataSource = new MatTableDataSource();

  @Output() needDataReload = new EventEmitter<any>();

  @Input() set datas(dats: any[]) {
    if (dats) {
      this._datas = dats;
    }
  }
  get datas() { return this._datas; }
  @Input() set options(opts: ListingOptions) {
    this._options = opts;
  }
  get options() { return this._options; }
  @Output() optionsChange: EventEmitter<ListingOptions> = new EventEmitter<ListingOptions>();


  @Output() deletedObject = new EventEmitter<any>();

  displayedColumns: string[];
  searchTerms: string;
  searching = false;

  constructor(private cd: ChangeDetectorRef, public dialog: MatDialog, private api: APIDatasService) {
    this._options = { "table_options": [], "type_options": {} };
    this.displayedColumns = [];
    this._datas = [];
    this.datas$ = new BehaviorSubject(this._datas);
    this.datas$.subscribe(data => this.dataSource.data = data);
  }

  updateSearchTerms(s: string) {
    this.searchTerms = s;
    if (this.searchTerms == "") {
      this.applyFilter(this.searchTerms)
      this.searching = false;
      this.fullReload()
    }
  }

  startSearch() {
    this.applyFilter(this.searchTerms)
    this.reloadWithoutPagination()
    this.searching = true;
  }

  getCustomActions() {

  }
  hasCustomActions() {
    return this.options.custom_actions.length
  }

  ngOnChanges(changes) {
    this.refresh();
  }
  ngAfterViewInit() {
    if (!this.options.page_options || this.searching)
      this.dataSource.paginator = this.paginator

  }
  ngOnInit() {
  }

  private updateCols() {
    if (this._options && this._options.table_options) {
      this.displayedColumns = this._options.table_options.map(x => x.property_name);
      this.displayedColumns.unshift("checkbox");
      this.displayedColumns.push("actions");
    }
  }

  fullReload() {
    this.needDataReload.emit();
  }

  softReload() {
    this.needDataReload.emit({
      softReload: true
    });
  }

  reloadWithoutPagination() {
    this.needDataReload.emit({
      softReload: true,
      withoutPagination: true
    });
  }

  refresh() {
    if (this.sort)
      this.dataSource.sort = this.sort;
    this.cd.detectChanges();
    this.updateCols()
    this.datas$.next(this._datas);
    if (this.table) {
      this.table.renderRows();
    }

  }

  onPaginatorChange($event) {
    if (this.options.page_options && !this.searching) {
      this.options.page_options.current_page = $event.pageIndex
      this.options.page_options.per_page = $event.pageSize
      this.optionsChange.emit(this.options)
      this.needDataReload.emit({
        softReload: true
      })
    }
  }

  openDeleteDialog(id) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialog, {
      width: '250px',
      data: { typename: this._options.type_options.type_name }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && this._options && this._options.type_options && this._options.type_options.base_route)
        this.api.delete(this._options.type_options.base_route, id).subscribe(
          res => {
            console.info("Successfully deleted " + this._options.type_options.type_name, res);
            this.deletedObject.emit();
          },
          error => {
            console.error("Error deleting " + this._options.type_options.type_name, error);
          }
        );
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getElementEditLink(element) {
    return '/' + this.options.type_options.base_route + '/edit/'
  }

  getFormattedDate(txt: string): string {
    return moment(txt).format("DD/MM/YYYY HH:mm");
  }
  checkboxChange(event, element) {
    console.log(event.checked, element);
    element.attributed = event.checked;
    const transformedElement = { ...element };
    
    if(transformedElement.order_date) {
        transformedElement.order_date = moment(transformedElement.order_date).format('YYYY-MM-DDTHH:mm:ss');
    }

    if(transformedElement.planned_date) {
        transformedElement.planned_date = moment(transformedElement.planned_date).format('YYYY-MM-DDTHH:mm:ss');
    }

    this.api.update(`/orders/${element._id}`, transformedElement).subscribe(
      res => {
        console.log('Order updated successfully');
      },
      err => {
        console.log('Error updating order', err);
      }
    );
  }

}

@Component({
  selector: 'listing-delete-dialog',
  template: "<h1 mat-dialog-title>Supprimer {{data.typename}}</h1> \
              <div mat-dialog-content> \
                 <p>Êtes-vous sûr de vouloir supprimer cet élément ?</p> \
              </div> \
              <div mat-dialog-actions> \
                <button mat-button (click)=\"onNoClick()\" cdkFocusInitial>Non</button> \
                <button mat-button [mat-dialog-close]=\"true\">Oui</button> \
              </div>",
})
export class ConfirmDeleteDialog {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DelDialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


}

export interface DelDialogData {
  typename: string
}

export interface paginateOption {
  current_page: number,
  per_page: number,
  item_count: number
}

export interface ListingOptions {
  "table_options": { "label": string, "property_name": string }[],
  "type_options": { "base_route"?: string, "type_name"?: string, param_routes?: any }
  showCheckbox?: boolean;
  custom_edit_action?: any
  custom_actions?: CustomAction[]
  page_options?: paginateOption
};
